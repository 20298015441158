<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<template>
  <div class="dialog-container">
    <el-dialog
      title="详情"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="1100px">
      <div class="table-container">
        <el-table
          :data="tableData"
          :height="tableHeight - 50"
          style="width: 100%"
          border
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            align="center"
            width="50"
            type="index"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="orderNum"
            align="center"
            label="订单编号"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="customName"
            align="center"
            label="客户姓名"
            min-width="110"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="customPhone"
            min-width="120"
            label="联系电话"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="businessEntityName"
            min-width="120"
            label="主体名称"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="merchantName"
            min-width="120"
            label="商户"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="venueName"
            min-width="120"
            label="场馆"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="sportItemName"
            min-width="120"
            label="运动项目"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="orderTypeName"
            min-width="120"
            label="订单类型"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="realPay"
            min-width="120"
            label="订单金额"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="payTypeName"
            min-width="120"
            label="支付类型"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="memberCardPayAmount"
            min-width="120"
            label="卡付金额"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="realPay"
            min-width="120"
            label="实付金额"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="orderTime"
            min-width="120"
            label="下单时间"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="payTime"
            min-width="120"
            label="支付时间"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="orderStatus"
            min-width="120"
            label="订单状态"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="refundMoney"
            min-width="120"
            label="退款金额"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="refundTime"
            min-width="120"
            label="退款时间"
          >
          </el-table-column>
        </el-table>
      </div>
      <div class="page-wrapper">
        <div class=""></div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="form.total"
        >
        </el-pagination>
      </div>
      <template slot="footer">
        <el-button class="footer-confirm" @click="exportUrlExcelPost(apis.exportFinancialStatementOrders, downData)">下载</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('close')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import mixins from "@/mixins/index";
import apis from '@/apis'
import DialogClass from "./dialogClass";
import download from "@/mixins/download";

export default {
  mixins: [mixin, mixins, download],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    // queryData: {
    //   required: true,
    //   type: Object,
    // },
  },
  data() {
    return {
      sceneList: [], //适用场景列表 根据适用场馆和场景类型获取
      showScene: false,
      addFlag: true,
      form: new DialogClass('form'),
      queryData: {},
      tableData: [],
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.form = new DialogClass('form')
      this.tableData = []
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.isEdit)
          if (this.isEdit) {
            this.$http.put(apis.updateCoupon, this.form).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.closeDialog()
              }
            })
          } else {
            delete this.form.id
            if (this.addFlag) {
              this.addFlag = false
              this.$http.post(apis.saveCoupon, this.form).then((res) => {
                this.addFlag = true
                if (res.data.code === 0) {
                  // 调用关闭方法，不要再写一次
                  this.closeDialog()
                }
              })
            }
          }
        }
      })
      console.log(`我执行了点确定的业务需求`)
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.queryData = data
      const formData = { ...data, ...this.form }
      this.downData = { ...formData }
      this.downData.pageNum = ''
      this.downData.pageSize = ''
      this.$http.post(apis.queryFinancialStatementOrders, formData).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    // 查询表格
    searchDatas() {
      this.getDataQuery(this.queryData)
    },
    /**
     * @function 分页尺寸修改方法
     * @param {Number} pageSize 修改后的分页大小
     */
    handleSizeChange(pageSize) {
      this.form.pageSize = pageSize
      // 业务问题，每次修改分页大小后要不要重新返回第一页
      this.form.pageNum = 1
      this.searchDatas()
    },
    /**
     * @function 当前页修改方法
     * @param {Number} pageNum 修改后的当前页
     */
    handleCurrentChange(pageNum) {
      this.form.pageNum = pageNum
      this.searchDatas()
    },
  },
}
</script>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
<style lang="less" scoped>
/deep/.el-input-number .el-input__inner{
  text-align: left!important;
}
</style>
